import React from 'react'
import Helmet from 'react-helmet'
import Waypoint from 'react-waypoint'
import Layout from '../components/layout'
import Header from '../components/Header'
import Nav from '../components/Nav'
import pic01 from '../assets/images/pic01.jpg'

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stickyNav: false
    }
  }

  _handleWaypointEnter= () => {
    this.setState(() => ({ stickyNav: false }));
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }));
  }

  render() {

    return (
      <Layout>
        <Helmet title="North Atlantic Tech" />

        <Header />

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        >
        </Waypoint>
        <Nav sticky={this.state.stickyNav} />

        <div id="main">
          <section id="intro" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>What we do</h2>
                </header>
                <p>We use cutting-edge full-stack development practices to drive growth across the finance, manufacturing, advertising, and education industries.</p>
              </div>
              <span className="image"><img src={pic01} alt="" /></span>
            </div>
          </section>

          <section id="first" className="main special">
            <header className="major">
              <h2>Services</h2>
            </header>
            <ul className="features">
              <li>
                <span className="icon major style1 fa-bar-chart"></span>
                <h3>Digital Advertising Analytics</h3>
                <p>We use complicated image and video recognition technology to find what your audience and customers love. Using advanced analytics, we find what your top competitors are doing right and what they are doing wrong, so you can compete with the best</p>
              </li>
              <li>
                <span className="icon major style3 fa-code"></span>
                <h3>Data Collection and Analysis</h3>
                <p>We use advanced scraping and data collection technology to bring the best results to our clients and services. From Amazon data, to specific competitor research, we can find, analyze, and present the data that will grow your business.</p>
              </li>
              <li>
                <span className="icon major style5 fa-search"></span>
                <h3>Search Engine Optimization</h3>
                <p>We know how Google works inside and out, and know how to bring you to the first page. Our blogger and influencer outreach programs, as well as our on-page SEO tricks will have you seeing increased views and sales in no time.</p>
              </li>
              <li>
                <span className="icon major style5 fa-briefcase"></span>
                <h3>Private Holdings Management</h3>
                <p>North Atlantic is constantly developing and starting new properties in a wide variety of sectors, all focused on automation and technology driven management</p>
              </li>
            </ul>
            {/**
            <footer className="major">
              <ul className="actions">
                <li><Link to="/generic" className="button">Learn More</Link></li>
              </ul>
            </footer>
            */}

          </section>
      {/**
      <section id="second" className="main special">
              <header className="major">
                <h2>Ipsum consequat</h2>
                <p>Donec imperdiet consequat consequat. Suspendisse feugiat congue<br />
                posuere. Nulla massa urna, fermentum eget quam aliquet.</p>
              </header>
              <ul className="statistics">
                <li className="style1">
                  <span className="icon fa-code-fork"></span>
                  <strong>5,120</strong> Etiam
                </li>
                <li className="style2">
                  <span className="icon fa-folder-open-o"></span>
                  <strong>8,192</strong> Magna
                </li>
                <li className="style3">
                  <span className="icon fa-signal"></span>
                  <strong>2,048</strong> Tempus
                </li>
                <li className="style4">
                  <span className="icon fa-laptop"></span>
                  <strong>4,096</strong> Aliquam
                </li>
                <li className="style5">
                  <span className="icon fa-diamond"></span>
                  <strong>1,024</strong> Nullam
                </li>
              </ul>
              <p className="content">Nam elementum nisl et mi a commodo porttitor. Morbi sit amet nisl eu arcu faucibus hendrerit vel a risus. Nam a orci mi, elementum ac arcu sit amet, fermentum pellentesque et purus. Integer maximus varius lorem, sed convallis diam accumsan sed. Etiam porttitor placerat sapien, sed eleifend a enim pulvinar faucibus semper quis ut arcu. Ut non nisl a mollis est efficitur vestibulum. Integer eget purus nec nulla mattis et accumsan ut magna libero. Morbi auctor iaculis porttitor. Sed ut magna ac risus et hendrerit scelerisque. Praesent eleifend lacus in lectus aliquam porta. Cras eu ornare dui curabitur lacinia.</p>
              <footer className="major">
                <ul className="actions">
                  <li><Link to="/generic" className="button">Learn More</Link></li>
                </ul>
              </footer>
            </section>
      */}
          
          <section id="cta" className="main special">
            <header className="major">
              <h2>Contact Us</h2>
              <p>Let us know what you're up to and how we can help with the form below<br /></p>
              <form   
                name="contact"
                method="post"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
              >
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value="contact" />
                <p>
                  <label htmlFor="name">Name
                    <input type="text" name="name" id="name" />
                  </label>
                </p>
                <p>
                  <label htmlFor="email">Email
                    <input type="text" name="email" id="email" />
                  </label>
                </p>
                <p>
                  <label htmlFor="message">Message
                  <textarea name="message" id="message" rows="6" />
                  </label>
                </p>
                <ul className="actions">
                  <br></br>
                  <li>
                    <button type="submit">Send</button>
                  </li>
                </ul>
              </form>
            </header>
          </section>
        </div>

      </Layout>
    )
  }
}

export default Index
