import React from 'react'

// import logo from '../assets/images/logo.svg';

const Header = (props) => (
    <header id="header" className="alt">
        {/**
            <span className="logo"><img src={logo} alt="" /></span>
         */}
        <h1>North Atlantic Tech</h1>
        <p>API Development - SEO - Data Science - Software Automation - Technology Holdings </p>
    </header>
)

export default Header
